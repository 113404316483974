<header class="page-header" role="banner" id="design_header">
  <div class="page-logo">
    <a href="#" class="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal"
      data-target="#modal-shortcut">
      <img [attr.src]="dmTmpl + '/img/logo.png'" alt="{{ AppCompTitle }}" aria-roledescription="logo">
      <span class="page-logo-text mr-1">
        {{ AppCompTitle }}
      </span>
      <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
      <em class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></em>
    </a>
  </div>
  <div class="hidden-md-down dropdown-icon-menu position-relative">
    <button type="button" class="rounded-0 header-btn btn js-waves-off"
    data-action="toggle" data-class="nav-function-hidden"
    title="Ocultar Navegacion">
      <em class="ni ni-menu"></em>
    </button>
    <ul>
      <li>
        <button type="button" class="rounded-0 btn js-waves-off"
        data-action="toggle" data-class="nav-function-minify"
        title="Minimizar Navegacion">
          <em class="ni ni-minify-nav"></em>
        </button>
      </li>
      <li>
        <button type="button" class="rounded-0 btn js-waves-off"
        data-action="toggle" data-class="nav-function-fixed"
        title="Bloquear Navegacion">
          <em class="ni ni-lock-nav"></em>
        </button>
      </li>
    </ul>
  </div>
  <div class="hidden-lg-up">
    <button type="button" class="rounded-0 header-btn btn press-scale-down"
    data-action="toggle" data-class="mobile-nav-on"
    title="Nuevo Menu">
      <em class="ni ni-menu"></em>
    </button>
  </div>
  <div class="ml-auto d-flex">
    <div class="">
      <a href="#" class="header-icon" data-toggle="modal" data-target=".modal-setting" title="Configuraciones">
        <em class="fal fa-cog"></em>
      </a>
    </div>
    <div class="">
      <a href="#" class="header-icon d-flex align-items-center justify-content-center ml-2" data-toggle="modal"
        data-target=".modal-profile" title="Opciones">
        <img src="https://cdn-icons-png.flaticon.com/512/2133/2133152.png"
        class="profile-image rounded-circle"
        alt="Usuario">
      </a>
    </div>
  </div>
</header>
