import { Component } from '@angular/core';

@Component({
  selector: 'app-int-modal',
  templateUrl: './int-modal.component.html',
  styleUrls: ['./int-modal.component.scss']
})
export class IntModalComponent {

}
