import { Component } from '@angular/core';

@Component({
  selector: 'app-int-terminal',
  templateUrl: './int-terminal.component.html',
  styleUrls: ['./int-terminal.component.scss']
})
export class IntTerminalComponent {

}
