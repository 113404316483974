<aside class="page-sidebar">
  <div class="page-logo">
    <a href="#" class="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal"
      data-target="#modal-shortcut">
      <img [attr.src]="dmTmpl + '/img/logo.png'" alt="SmartAdmin WebApp"
        aria-roledescription="logo">
      <span class="page-logo-text mr-1">
        {{ AppCompTitle }}
      </span>
      <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
      <em class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></em>
    </a>
  </div>
  <nav id="js-primary-nav" class="primary-nav" role="navigation">
    <div class="nav-filter">
      <div class="position-relative">
        <input type="text" id="nav_filter_input" placeholder="Filtrar Menu" class="rounded-0 form-control" tabindex="0">
        <button type="button" onclick="return false;" data-action="toggle"
        class="rounded-0 btn-primary btn-search-close js-waves-off" title="Funcionalidad"
        data-class="list-filter-active" data-target=".page-sidebar">
          <em class="fal fa-chevron-up"></em>
        </button>
      </div>
    </div>
    <div class="info-card">
      <a href="profile">
        <img src="https://cdn-icons-png.flaticon.com/512/2133/2133152.png"
        class="profile-image rounded-circle"
        alt="Desarrollador Programador">
      </a>
      <div class="info-card-text">
        <span class="d-inline-block text-truncate text-truncate-sm">
          Bienvenido Usuario!
        </span>
        <span class="d-inline-block text-truncate text-truncate-sm">
          User: root
        </span>
        <span class="d-inline-block text-truncate text-truncate-sm">
          Rol: Desarrollador
        </span>
      </div>
      <img src="{{ dmTmpl }}/img/card-backgrounds/cover-2-lg.png" class="cover"
        alt="cover">
      <a href="#" onclick="return false;" class="pull-trigger-btn" data-action="toggle" data-class="list-filter-active"
        data-target=".page-sidebar" data-focus="nav_filter_input">
        <em class="fal fa-angle-down"></em>
      </a>
    </div>
    <ul id="js-nav-menu" class="nav-menu">

      <li *ngFor="let module of responseData" class="mb-2">
        <a [routerLink]="['/internal', module.mo_sy_prefix_os_template, module.tb_sy_module_os_table]"
        [attr.data-filter-tags]="module.tb_sy_module_os_name"
        [attr.title]="module.tb_sy_module_os_name"
        class="p-2 waves-effect waves-themed text-white">
          <em class="text-white {{module.mo_sy_icon_os_name}}"></em>
          <span class="nav-link-text">{{module.tb_sy_module_os_name}}</span>
        </a>
      </li>


      <!--
        <li class="nav-title text-white" *ngIf="syPrefixSy === 'Sistemas'">
          <span>{{ syPrefixSy }}</span>
        </li>

        <li class="mb-2">
          <a href="#" [routerLink]="['system/attribute']" data-filter-tags="Atributos"
          title="Atributos" class="p-2 waves-effect waves-themed text-white">
            <em class="far fa-table"></em>
            <span class="nav-link-text" data-i18n="nav.Atributos">
              Atributos
            </span>
          </a>
        </li>
      -->

    </ul>
    <div class="filter-message js-filter-message bg-success-600"></div>
  </nav>
  <div class="nav-footer shadow-top d-none">
    <a href="#" onclick="return false;" data-action="toggle"
    data-class="nav-function-minify" class="hidden-md-down">
      <em class="ni ni-chevron-right"></em>
      <em class="ni ni-chevron-right"></em>
    </a>
    <ul class="list-table m-auto nav-footer-buttons">
      <li>
        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Chat logs">
          <em class="fal fa-comments"></em>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Support Chat">
          <em class="fal fa-life-ring"></em>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Make a call">
          <em class="fal fa-phone"></em>
        </a>
      </li>
    </ul>
  </div>
</aside>
