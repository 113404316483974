import { Component } from '@angular/core';

@Component({
  selector: 'app-gen-error',
  templateUrl: './gen-error.component.html',
  styleUrls: ['./gen-error.component.scss']
})
export class GenErrorComponent {

}
