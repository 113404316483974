<div class="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div>
<footer class="page-footer" role="contentinfo" id="design_footer">
  <div class="d-flex align-items-center flex-1 text-muted">
    <span class="hidden-md-down fw-700">
      <a href="" class="text-primary fw-500" title="">
        {{ AppCompTitle }}
      </a>
      <span>2023 © Desarrollado Por Adison Jimenez</span>
    </span>
  </div>
  <div class="d-none">
    <ul class="list-table m-0">
      <li class="pl-3">
        <a href="privacy" class="text-secondary fw-700">
          <span>Privacidad</span>
        </a>
      </li>
      <li class="pl-3">
        <a href="license" class="text-secondary fw-700">
          <span>Licencia</span>
        </a>
      </li>
      <li class="pl-3">
        <a href="documentation" class="text-secondary fw-700">
          <span>Documentacion</span>
        </a>
      </li>
      <li class="pl-3">
        <a href="question" class="text-secondary fw-700">
          <span>Preguntas</span>
        </a>
      </li>
    </ul>
  </div>
</footer>
