<div *ngIf="isLoading" class="loading-init">
  <div class="loading-spinner"></div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
    <div aria-live="polite" aria-atomic="true" class="position-relative">
      <div class="toast-container position-absolute p-3 top-0 start-50 translate-middle-x">
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" id="liveToast">
          <div class="toast-header">
            <svg class="bd-placeholder-img rounded-0 me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
              <rect width="100%" height="100%" fill="#007aff"></rect>
            </svg>
            <strong class="me-auto">Bienvenido Usuario</strong>
            <small class="text-muted">Justo Ahora</small>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Ingresa tus credenciales para tener acceso.
          </div>
        </div>
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" id="liveToastTwo">
          <div class="toast-header">
            <svg class="bd-placeholder-img rounded-0 me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
              <rect width="100%" height="100%" fill="#007aff"></rect>
            </svg>
            <strong class="me-auto">Bienvenido Usuario</strong>
            <small class="text-muted">Justo Ahora</small>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Otro Mensaje
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
    <div class="d-flex justify-content-center">
      <form aria-label="" class="form-horizontal" role="form"
      enctype="application/x-www-form-urlencoded" id="formLoginData">
        <div class="modal modal-sheet position-static d-block p-4 py-md-5" tabindex="-1" role="dialog" id="modalLogin">
          <div class="modal-dialog" role="document">
            <div class="modal-content rounded-0 shadow">
              <div class="modal-header pb-4 border-bottom-0"></div>
              <div class="modal-body p-5 pt-0 text-center">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <h1 class="fw-bold mb-0 fs-2 text-center">Ingreso Sistema</h1>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <div class="hide" id="form_ajax_login"></div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 d-none">
                    <div class="alert alert-danger alert-dismissable p-2 rounded-0">
                      <strong>Error:</strong>
                      <span>Haz ingresado incorrectamente al enlace proporcionado.</span>
                    </div>

                    <div class="alert alert-success alert-dismissable p-2 rounded-0">
                      <strong>Exito:</strong>
                      <span>Tu contraseña fue restaurada correctamente.</span>
                    </div>

                    <div class="alert alert-danger alert-dismissable p-2 rounded-0">
                      <strong>Error:</strong>
                      <span>No se puedo realizar la accion del registro, vuleve a iniciar sesión nuevamente.</span>
                    </div>

                    <div class="alert alert-danger alert-dismissable p-2 rounded-0">
                      <strong>Error:</strong>
                      <span>El enlace cargado no es el correcto, corrobora el enlace.</span>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0">
                    <div class="form-group form-floating mb-3">
                      <input type="text" name="login_os_login" id="login_os_login" class="form-control rounded-0"
                        placeholder="Digita el texto ..." required="" autocomplete="off" />
                      <label for="login_os_login">
                        <span>Usuario:</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0">
                    <div class="form-group form-floating mb-3">
                      <input type="password" name="login_os_password" id="login_os_password" class="form-control rounded-0"
                        placeholder="Digita el texto ..." required="" autocomplete="off" />
                      <label for="login_os_password">
                        <span>Contraseña:</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0">
                    <div class="row mt-3">
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <div class="form-group">
                          <button type="button" (click)="actionLogin()"
                            class="btn btn-md btn-block btn-primary btn-custom w-md w-100 rounded-0 waves-effect waves-light">
                            <span>Loguearme</span>
                          </button>
                          <!--<a href="#" [routerLink]="['../../internal/dashboard']"
                            class="btn btn-md btn-block btn-primary btn-custom w-md w-100 rounded-0 waves-effect waves-light">
                            <span>Loguearme</span>
                          </a>-->
                        </div>
                      </div>
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                        <div class="form-group">
                          <a href="recover"
                            class="btn btn-md btn-block btn-warning btn-custom w-md w-100 rounded-0 waves-effect waves-light">
                            <span>Recordarme</span>
                          </a>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-none">
                          <div class="form-group">
                            <a href="register" class="btn btn-md btn-block btn-success btn-custom w-md w-100 rounded-0 waves-effect waves-light">
                              <span>Registrarme</span>
                            </a>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <span>
                  Ingreso Permitido: Usuarios Registrados
                </span>
                <hr class="my-4">
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Ventana Modal: Sistema Registro -->
<form aria-label="" class="form-horizontal needs-validation was-validated"
enctype="application/x-www-form-urlencoded" id="formSystemData" novalidate="" role="form">
  <div aria-hidden="true" aria-labelledby="myCenterModalLabel"
  data-backdrop="static" data-keyboard="false" data-bs-backdrop="static" data-bs-keyboard="false"
  id="modalSystem" role="dialog" tabindex="-1" style="display: none;" class="modal fade modal-system">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="rounded-0 modal-content">
        <div class="rounded-0 modal-header bg-danger text-white">
          <h5 class="modal-title text-white">
            <span>Error</span>
          </h5>
          <button type="button" class="close text-white d-none" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <em class="far fa-times"></em>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
              <div class="alert alert-primary alert-dismissible fade show p-1 rounded-0 mb-0" role="alert">
                <div class="d-flex align-items-center">
                  <div class="alert-icon width-1">
                    <span class="icon-stack" style="font-size: 20px;">
                      <em class="base-15 icon-stack-3x color-primary-400"></em>
                      <em class="base-10 text-white icon-stack-1x"></em>
                      <em class="far fa-info-square color-primary-800 icon-stack-2x"></em>
                    </span>
                  </div>
                  <div class="flex-1">
                    <span id="formSystemMssg"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
              <div class="alert alert-info alert-dismissible fade show p-1 rounded-0 mb-0" role="alert">
                <div class="d-flex align-items-center">
                  <div class="flex-1" style="max-height: 100vh; overflow-y: auto;">
                    <pre style="white-space: pre-wrap;" id="formSystemAjax"></pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="rounded-0 btn btn-sm waves-effect waves-light btn btn-outline-primary"
          data-dismiss="modal" data-bs-dismiss="modal" type="button" (click)="modalClass();">
            <em class="far fa-times-circle mr-1"></em>
            <span class="">Cerrar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
