import { Component } from '@angular/core';

@Component({
  selector: 'app-int-initial',
  templateUrl: './int-initial.component.html',
  styleUrls: ['./int-initial.component.scss']
})
export class IntInitialComponent {

}
