<app-int-option></app-int-option>
<div class="page-wrapper">
  <div class="page-inner">
    <app-int-sidebar></app-int-sidebar>
    <div class="page-content-wrapper">
      <app-int-header></app-int-header>
      <main id="js-page-content" role="main" class="page-content p-3" id="design_main">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</div>
<app-int-footer></app-int-footer>
<app-int-color></app-int-color>
<!--<app-int-modal></app-int-modal>-->
<app-int-module></app-int-module>
<app-int-profile></app-int-profile>
<app-int-setting></app-int-setting>
<app-int-logout></app-int-logout>
