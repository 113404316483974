<div class="modal fade modal-setting" tabindex="-1" role="dialog" aria-hidden="true" data-keyboard="false"
  data-backdrop="static" aria-labelledby="myCenterModalLabel" style="display: none;">
  <div class="modal-dialog modal-sm modal-dialog-right">
    <div class="rounded-0 modal-content">
      <div class="rounded-0 modal-header bg-trans-gradient d-flex justify-content-center align-items-center">
        <h5 class="m-0 text-center color-white">
          <span>Configuraciones</span>
        </h5>
        <button type="button" class="close text-white position-absolute pos-right p-2 m-1 mr-2" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <em class="far fa-times"></em>
          </span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="settings-panel">
          <div class="mt-4 d-table w-100 pl-5 pr-3">
            <div class="d-table-cell align-middle">
              <h5 class="p-0">
                <span>Tamaño De Fuente Global</span>
              </h5>
            </div>
          </div>
          <div class="list mt-1">
            <div class="btn-group btn-group-sm btn-group-toggle my-2" data-toggle="buttons">
              <label class="btn btn-outline-primary btn-xs"
              data-action="toggle-swap" data-class="root-text-sm"
              data-target="html">
                <input type="radio" name="changeFrontSize" checked=""> SM
              </label>
              <label class="btn btn-outline-primary btn-xs"
              data-action="toggle-swap" data-class="root-text"
              data-target="html">
                <input type="radio" name="changeFrontSize"> MD
              </label>
              <label class="btn btn-outline-primary btn-xs"
              data-action="toggle-swap" data-class="root-text-lg"
              data-target="html">
                <input type="radio" name="changeFrontSize"> LG
              </label>
              <label class="btn btn-outline-primary btn-xs"
              data-action="toggle-swap" data-class="root-text-xl"
              data-target="html">
                <input type="radio" name="changeFrontSize"> XL
              </label>
            </div>
          </div>
          <hr class="mb-0 mt-4">
          <div class="mt-4 d-table w-100 pl-5 pr-3">
            <div class="d-table-cell align-middle">
              <h5 class="p-0 pr-2 d-flex">
                <span>Colores Del Tema</span>
              </h5>
            </div>
          </div>
          <div class="expanded theme-colors pl-5 pr-3">
            <ul class="m-0">
              <li>
                <a href="#" id="myapp-0" data-action="theme-update" data-themesave data-theme="" data-toggle="tooltip"
                  data-placement="top" title="Wisteria (base css)" data-original-title="Wisteria (base css)"></a>
              </li>
              <li>
                <a href="#" id="myapp-1" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-1.css'" data-toggle="tooltip"
                  data-placement="top" title="Tapestry" data-original-title="Tapestry"></a>
              </li>
              <li>
                <a href="#" id="myapp-2" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-2.css'" data-toggle="tooltip"
                  data-placement="top" title="Atlantis" data-original-title="Atlantis"></a>
              </li>
              <li>
                <a href="#" id="myapp-3" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-3.css'" data-toggle="tooltip"
                  data-placement="top" title="Indigo" data-original-title="Indigo"></a>
              </li>
              <li>
                <a href="#" id="myapp-4" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-4.css'" data-toggle="tooltip"
                  data-placement="top" title="Dodger Blue" data-original-title="Dodger Blue"></a>
              </li>
              <li>
                <a href="#" id="myapp-5" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-5.css'" data-toggle="tooltip"
                  data-placement="top" title="Tradewind" data-original-title="Tradewind"></a>
              </li>
              <li>
                <a href="#" id="myapp-6" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-6.css'" data-toggle="tooltip"
                  data-placement="top" title="Cranberry" data-original-title="Cranberry"></a>
              </li>
              <li>
                <a href="#" id="myapp-7" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-7.css'" data-toggle="tooltip"
                  data-placement="top" title="Oslo Gray" data-original-title="Oslo Gray"></a>
              </li>
              <li>
                <a href="#" id="myapp-8" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-8.css'" data-toggle="tooltip"
                  data-placement="top" title="Chetwode Blue" data-original-title="Chetwode Blue"></a>
              </li>
              <li>
                <a href="#" id="myapp-9" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-9.css'" data-toggle="tooltip"
                  data-placement="top" title="Apricot" data-original-title="Apricot"></a>
              </li>
              <li>
                <a href="#" id="myapp-10" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-10.css'" data-toggle="tooltip"
                  data-placement="top" title="Blue Smoke" data-original-title="Blue Smoke"></a>
              </li>
              <li>
                <a href="#" id="myapp-11" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-11.css'" data-toggle="tooltip"
                  data-placement="top" title="Green Smoke" data-original-title="Green Smoke"></a>
              </li>
              <li>
                <a href="#" id="myapp-12" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-12.css'" data-toggle="tooltip"
                  data-placement="top" title="Wild Blue Yonder" data-original-title="Wild Blue Yonder"></a>
              </li>
              <li>
                <a href="#" id="myapp-13" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-13.css'" data-toggle="tooltip"
                  data-placement="top" title="Emerald" data-original-title="Emerald"></a>
              </li>
              <li>
                <a href="#" id="myapp-14" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-14.css'" data-toggle="tooltip"
                  data-placement="top" title="Supernova" data-original-title="Supernova"></a>
              </li>
              <li>
                <a href="#" id="myapp-15" data-action="theme-update" data-themesave
                  [attr.data-theme]="dmTmpl + '/dist/css/themes/cust-theme-15.css'" data-toggle="tooltip"
                  data-placement="top" title="Hoki" data-original-title="Hoki"></a>
              </li>
            </ul>
          </div>
          <hr class="mb-0 mt-4">
          <div class="mt-4 d-table w-100 pl-5 pr-3">
            <div class="d-table-cell align-middle">
              <h5 class="p-0 pr-2 d-flex">
                <span>Modos De Tema (Beta)</span>
              </h5>
            </div>
          </div>
          <div class="pl-5 pr-3 py-3">
            <div class="ie-only alert alert-warning d-none">
              <h6>Problema de Internet Explorer</h6>
              <span>Es posible que este componente en particular no funcione como se esperaba en Internet Explorer.
                Úselo con precaución.</span>
            </div>
            <div class="row no-gutters">
              <div class="col-4 pr-2 text-center">
                <div id="skin-default" data-action="toggle-replace" data-replaceclass="mod-skin-light mod-skin-dark"
                  data-class="" data-toggle="tooltip" data-placement="top" title=""
                  class="d-flex bg-white border border-primary rounded overflow-hidden text-success js-waves-on"
                  data-original-title="Default Mode" style="height: 80px">
                  <div class="bg-primary-600 bg-primary-gradient px-2 pt-0 border-right border-primary"></div>
                  <div class="d-flex flex-column flex-1">
                    <div class="bg-white border-bottom border-primary py-1"></div>
                    <div class="bg-faded flex-1 pt-3 pb-3 px-2">
                      <div class="py-3"
                        style="background:url('{{ dmTmpl }}/img/demo/s-1.png') top left no-repeat;background-size: 100%;">
                      </div>
                    </div>
                  </div>
                </div>
                <span>Defecto</span>
              </div>
              <div class="col-4 px-1 text-center">
                <div id="skin-light" data-action="toggle-replace" data-replaceclass="mod-skin-dark"
                  data-class="mod-skin-light" data-toggle="tooltip" data-placement="top" title=""
                  class="d-flex bg-white border border-secondary rounded overflow-hidden text-success js-waves-on"
                  data-original-title="Light Mode" style="height: 80px">
                  <div class="bg-white px-2 pt-0 border-right border-"></div>
                  <div class="d-flex flex-column flex-1">
                    <div class="bg-white border-bottom border- py-1"></div>
                    <div class="bg-white flex-1 pt-3 pb-3 px-2">
                      <div class="py-3"
                        style="background:url('{{ dmTmpl }}/img/demo/s-1.png') top left no-repeat;background-size: 100%;">
                      </div>
                    </div>
                  </div>
                </div>
                <span>Blanco</span>
              </div>
              <div class="col-4 pl-2 text-center">
                <div id="skin-dark" data-action="toggle-replace" data-replaceclass="mod-skin-light"
                  data-class="mod-skin-dark" data-toggle="tooltip" data-placement="top" title=""
                  class="d-flex bg-white border border-dark rounded overflow-hidden text-success js-waves-on"
                  data-original-title="Dark Mode" style="height: 80px">
                  <div class="bg-fusion-500 px-2 pt-0 border-right"></div>
                  <div class="d-flex flex-column flex-1">
                    <div class="bg-fusion-600 border-bottom py-1"></div>
                    <div class="bg-fusion-300 flex-1 pt-3 pb-3 px-2">
                      <div class="py-3 opacity-30"
                        style="background:url('{{ dmTmpl }}/img/demo/s-1.png') top left no-repeat;background-size: 100%;">
                      </div>
                    </div>
                  </div>
                </div>
                <span>Oscuro</span>
              </div>
            </div>
          </div>
          <hr class="mb-0 mt-4">
        </div>
        <span id="saving"></span>
      </div>
    </div>
  </div>
</div>
