import { Component } from '@angular/core';

@Component({
  selector: 'app-int-module',
  templateUrl: './int-module.component.html',
  styleUrls: ['./int-module.component.scss']
})
export class IntModuleComponent {

}
