<div class="modal fade modal-profile" tabindex="-1" role="dialog" aria-hidden="true" data-keyboard="false"
  data-backdrop="static" aria-labelledby="myCenterModalLabel" style="display: none;">
  <div class="modal-dialog modal-sm modal-dialog-right">
    <div class="rounded-0 modal-content">
      <div class="rounded-0 modal-header bg-trans-gradient d-flex align-items-center">
        <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">
          <span class="mr-2">
            <img src="https://cdn-icons-png.flaticon.com/512/2133/2133152.png"
              class="rounded-circle profile-image d-block" alt="root">
          </span>
          <div class="info-card-text">
            <div class="fs-lg text-truncate text-truncate-lg text-white">
              Desarrollador Programador
            </div>
            <span class="text-truncate text-truncate-md opacity-80">
              Rol: Desarrollador
            </span>
          </div>
        </div>
        <button type="button" class="close text-white position-absolute pos-right p-2 m-1 mr-2" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <em class="far fa-times"></em>
          </span>
        </button>
      </div>
      <div class="modal-body p-0">
        <a href="internal/dashboard" class="dropdown-item fw-500 pt-3 pb-3">
          <em class="fal fa-tachometer-alt-average"></em>
          <span>
            Dashboard
          </span>
        </a>
        <!--
          <div class="dropdown-divider m-0"></div>
          <a href="#" class="dropdown-item fw-500 pt-3 pb-3">
            <em class="fal fa-user-astronaut"></em>
            <span>
              Mi Cuenta
            </span>
          </a>
          <div class="dropdown-divider m-0"></div>
          <a href="#" class="dropdown-item fw-500 pt-3 pb-3">
            <em class="fal fa-cog"></em>
            <span>
              Personalizacion
            </span>
          </a>
        -->
        <div class="dropdown-divider m-0"></div>
        <a href="#" class="dropdown-item fw-500 pt-3 pb-3"
        tabindex="0" aria-controls="dt_read"
        data-toggle="modal" data-target=".modal-div-logout"
        (click)="modalClass();">
          <em class="ni ni-logout"></em>
          <span>
            Cerrar Sesion
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
