
<!-- Ventana Modal: Error Registro -->
<form aria-label="" class="form-horizontal needs-validation was-validated"
enctype="application/x-www-form-urlencoded" id="formErrorData" novalidate="" role="form">
  <div aria-hidden="true" aria-labelledby="myCenterModalLabel"
  data-backdrop="static" data-keyboard="false" data-bs-backdrop="static" data-bs-keyboard="false"
  id="modalError" role="dialog" tabindex="-1" style="display: none;" class="modal fade modal-error">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white">
          <h5 class="modal-title text-white">
            <span>Error Registro</span>
          </h5>
          <button type="button" class="close text-white" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <em class="far fa-times"></em>
            </span>
          </button>
        </div>
        <div class="modal-body">

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0">
              <div class="hide" id="formErrorAjax"></div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              Error
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button class="rounded-0 btn btn-sm waves-effect waves-light btn btn-outline-primary"
          data-dismiss="modal" data-bs-dismiss="modal" type="button">
            <em class="far fa-times-circle mr-1"></em>
            <span class="">Cerrar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
