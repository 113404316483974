<nav class="navbar navbar-expand-lg bg-body-tertiary bg-primary">
  <div class="container">
    <a class="navbar-brand" href="external/login">
      <img [attr.src]="dmTmpl + '/img/logo.png'" alt="Logo">
      <span class="text-white">
        {{ AppCompTitle }}
      </span>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="external/login">
            <h4 class="text-white">
              Inicio
            </h4>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" href="external/login">
            <h4 class="text-white">
              Ingreso
            </h4>
          </a>
        </li>
        <li class="nav-item d-none">
          <a class="nav-link active" href="external/register">
            <h4 class="text-white">
              Registro
            </h4>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container py-3">
  <router-outlet></router-outlet>
</div>
