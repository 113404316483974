<div class="modal fade modal-backdrop-transparent" id="modal-shortcut" tabindex="-1" role="dialog"
  aria-labelledby="modal-shortcut" aria-hidden="true">
  <div class="modal-dialog modal-dialog-top modal-transparent" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <ul class="app-list w-auto h-auto p-0 text-left">
          <li>
            <a href="internal/dashboard" class="app-list-item text-white border-0 m-0">
              <div class="icon-stack">
                <em class="base base-7 icon-stack-3x opacity-100 color-primary-500 "></em>
                <em class="base base-7 icon-stack-2x opacity-100 color-primary-300 "></em>
                <em class="far fa-home icon-stack-1x opacity-100 color-white"></em>
              </div>
              <span class="app-list-name">
                Dashboard
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
