import { Component } from '@angular/core';

@Component({
  selector: 'app-int-footer',
  templateUrl: './int-footer.component.html',
  styleUrls: ['./int-footer.component.scss']
})
export class IntFooterComponent {
  AppCompTitle = 'APP PQRS';
}
