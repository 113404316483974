import { Component } from '@angular/core';

@Component({
  selector: 'app-int-color',
  templateUrl: './int-color.component.html',
  styleUrls: ['./int-color.component.scss']
})
export class IntColorComponent {

}
