<div class="modal fade modal-div-logout" tabindex="-1" role="dialog" aria-hidden="true" id="modal-id-logout"
  data-keyboard="false" data-backdrop="static" aria-labelledby="myCenterModalLabel" style="display: none;">
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="rounded-0 modal-content">
      <div class="rounded-0 modal-header bg-dark text-white">
        <h5 class="modal-title text-white">Cerrar Sesion</h5>
        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">
            <em class="far fa-times"></em>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="form-group">
              <div class="input-group input-group-sm">
                <span>¿Ya terminaste de realizar tus actividades?</span>
              </div>
              <div class="input-group input-group-sm">
                <span>¿Estas seguro de cerrar tu sesion abierta?</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">
        <button class="rounded-0 btn btn-sm waves-effect waves-light btn-outline-danger"
        type="button" (click)="actionLogout();">
          <em class="far fa-portal-exit"></em>
          <span>
            Cerrar Sesion
          </span>
        </button>
        <button class="rounded-0 btn btn-sm waves-effect waves-light btn-outline-success"
        data-dismiss="modal" data-bs-dismiss="modal" type="button" (click)="modalClass();">
          <em class="far fa-times-circle"></em>
          <span>
            Cancelar
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
