import { Component } from '@angular/core';

@Component({
  selector: 'app-int-option',
  templateUrl: './int-option.component.html',
  styleUrls: ['./int-option.component.scss']
})
export class IntOptionComponent {

}
